import { Injectable, ElementRef, NgZone } from '@angular/core';
import { Subject, Subscription, takeWhile } from 'rxjs';
import { CallContainer, Message } from './types/message';
import { environment } from 'src/environments/environment';
import { Agent, UserType } from '../../models';
import { AuthService } from '../auth.service';
import { Socket, io } from 'socket.io-client';
import { ToastService } from '../toast.service';
import { TelnyxWebRTCService } from '../telnyx-web-rtc.service';
import { DialerTelnyxCallState } from '../../models/classes/call';
import { AudioService } from '../audio/audio.service';
import { captureException } from '@sentry/angular';

const offerOptions = {
  offerToReceiveAudio: true,
  offerToReceiveVideo: false
};

@Injectable({
  providedIn: 'root'
})
export class SuperviseDataService {
  public localAudio?: ElementRef;
  public remoteAudio?: ElementRef;

  public peerConnections: { [key: string]: RTCPeerConnection } = {}

  public localStream?: MediaStream;
  public localTracks: { [key: string]: MediaStreamTrack } = {};

  public audioContext?: AudioContext
  public mixedStreamDestination?: MediaStreamAudioDestinationNode

  get mixedStream() {
    return this.mixedStreamDestination?.stream
  }

  localAudioActive = false;

  remoteMediaStreams: { [key: string]: MediaStream } = {}
  audioElement: { [key: string]: HTMLAudioElement } = {}
  superviseMap: { [key: string]: { inCall?: boolean, loading?: boolean, error?: string } } = {}
  sendMyAudio: { [key: string]: boolean } = {}
  bargeEnabled: { [key: string]: { enabled?: boolean, loading?: boolean, error?: string } } = {}
  bargeEnabledStream: { [key: string]: MediaStream } = {}

  callStatusSubscription?: Subscription

  localSource?: MediaStreamAudioSourceNode
  remoteSource?: MediaStreamAudioSourceNode


  private socket?: Socket;

  private messagesSubject = new Subject<Message>();
  public messages$ = this.messagesSubject.asObservable();

  private _pingInterval?: NodeJS.Timeout;
  private _pingTimeout?: NodeJS.Timeout;
  private _reconnectTimeout?: NodeJS.Timeout;

  private _wasPongReconnect = false
  private socketWasInitialized = false


  constructor(
    private audioService: AudioService,
    private authService: AuthService,
    private ngZone: NgZone,
    private telnyxService: TelnyxWebRTCService,
    private toastService: ToastService,
  ) { }

  initialize() {
    this.addIncomingMessageHandler();
    this.callStatusHandler()

    this.initializeSocketCheck()

    this.initializeForceReconnect()
  }

  initializeForceReconnect() {
    this.authService.forceReconnectSupervise$.subscribe((user) => {
      this._wasPongReconnect = true
      console.log('forceReconnectSupervise')
      this.socketReconnect()
    })
  }

  initializeSocketCheck() {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        console.log('initial ping check')
        if (this._pingInterval) {
          clearInterval(this._pingInterval)
        }
        this._pingInterval = setInterval(() => {
          console.log('ping check run')
          if (!this.socket) {
            console.error('ping check no socket')
            if (this.socketWasInitialized) {
              this.connect()
            }
            return
          }
          this.socketWasInitialized = true
          if (!this.socket?.connected) {
            console.log('ping check socket not connected')
            this.socket.open();
          } else {

            this.socket.emit("ping");
            console.log("ping check send");

            // Set a timeout to check if pong is received
            this._pingTimeout = setTimeout(() => {
              if (this.socket) {
                console.log("ping check pong not received, attempting to reconnect...");
                this._wasPongReconnect = true
                this.socketReconnect()
              }
            }, 5000);
          }
        }, 60 * 1000);
      }, 10 * 1000)
    })
  }

  initializeLocalStream = (): Promise<void> => new Promise((resolve => {
    this.audioService.audioDevicesInitialized$.pipe(takeWhile((initialized) => !initialized, true)).subscribe(async (initialized) => {
      if (initialized) {
        try {
          const constraints = {
            audio: {
              deviceId: this.audioService?.audioSettings?.micId ? { exact: this.audioService.audioSettings.micId as string } : undefined,
            },
          }

          this.localStream = await navigator.mediaDevices.getUserMedia(constraints)

          if (!this.audioContext) {
            this.audioContext = new AudioContext();
          }
          if (!this.mixedStreamDestination) {
            this.mixedStreamDestination = this.audioContext.createMediaStreamDestination();
          }

          try {
            this.localSource = this.audioContext.createMediaStreamSource(this.localStream)
            this.localSource.connect(this.mixedStreamDestination);

          } catch (e: any) {
            console.error('superviseDataService initialize', e);
          }
        } catch (e: any) {
          console.error('superviseDataService initialize', e);
        }

        resolve()
      }
    })

    // this.audioService.audioSettingsChanged$.subscribe(async (settings) => {
    //   console.log('Audio Settings Changed', settings);
    //   await this.updateLocalStream(settings.deviceId as string);
    // think about disconnecting / reconnecting supervisor
    // });
  }))


  callStatusHandler() {
    this.callStatusSubscription = this.telnyxService.callStatus$.subscribe(
      (status) => {
        if (status === DialerTelnyxCallState.ONGOING) {
          const inCallCount = Object.values(this.superviseMap).filter((call) => call.inCall).length
          if (inCallCount > 0) {
            this.initializeRemoteStream()
          }
        } else if (status === DialerTelnyxCallState.IDLE) {
          this.remoteSource?.disconnect()
          delete this.remoteSource
        }
      },
    );
  }

  initializeRemoteStream() {
    if (!this.telnyxService.currentCall?.remoteStream) {
      console.log('wait for stream')
      setTimeout(() => this.initializeRemoteStream(), 500)
      return
    }

    console.log('initializeRemoteStream')

    if (!this.audioContext) {
      console.log('create remote audioContext')
      this.audioContext = new AudioContext();
    }

    if (!this.mixedStreamDestination) {
      console.log('create remote mixedStreamDestination')
      this.mixedStreamDestination = this.audioContext.createMediaStreamDestination();
    }

    if (this.telnyxService.currentCall.remoteStream && !this.remoteSource) {
      this.remoteSource = this.audioContext.createMediaStreamSource(
        this.telnyxService.currentCall.remoteStream,
      );

      console.log('remoteSource', this.remoteSource)

      this.remoteSource.connect(this.mixedStreamDestination);
    }
  }



  async supervise(agent: Agent, sendMyAudio = false) {
    if (agent.id) {
      if (this.superviseMap[agent.id] && this.sendMyAudio[agent.id] === sendMyAudio) {
        this.hangUp({
          agentId: agent.id,
          agencyId: agent.agencyId
        })
        return
      }

      const hasOpenConnection = Object.values(this.peerConnections).some((pc) => pc.iceConnectionState === 'connected');

      if (!hasOpenConnection) {
        console.log('hasOpenConnection', hasOpenConnection)
        this._wasPongReconnect = true
        this.socketReconnect()
      }


      if (!this.localStream) {
        await this.initializeLocalStream()
      }

      if (!this.localStream) {
        console.error('no localStream')
        throw new Error('no localStream')
      }

      if (!this.localTracks[agent.id]) {
        this.localTracks[agent.id] = this.localStream.getAudioTracks()[0].clone()
      }

      this.localTracks[agent.id].enabled = sendMyAudio
      // this.localStream.getAudioTracks().forEach(track => {
      //   track.enabled = sendMyAudio;
      //   console.log('track', track)
      // });

      // this.mixedStream.getAudioTracks().forEach(track => {
      //   track.enabled = true;
      // });
      this.sendMyAudio[agent.id] = sendMyAudio

      if (!this.superviseMap[agent.id]?.inCall) {
        this.createPeerConnection({
          agencyId: agent.agencyId,
          agentId: agent.id,
        });

        if (!agent.id) {
          console.error('no agentId')
          throw new Error('no agentId')
        }

        if (!this.peerConnections[agent.id]) {
          console.error('no peerConnection')
          throw new Error()
        }

        if (!this.localStream) {
          console.error('no localStream')
          throw new Error('no localStream')
        }
        this.peerConnections[agent.id].addTrack(this.localTracks[agent.id], this.localStream)
        // this.localStream.getTracks().forEach(track => {
        //   console.log('add local track', track)
        //   this.peerConnection?.addTrack(track, this.localStream)
        // });

        // if (sendMyAudio) {
        // this.mixedStream.getTracks().forEach(
        //   track => {
        //     console.log('add mixed track', track, this.peerConnection)
        //     this.peerConnection?.addTrack(track, this.mixedStream)
        //   }
        // );
        // }

        // this.peerConnection.addTrack(track, this.localStream)
        // this.peerConnection.addTrack(track, this.mixedStream)

        try {
          const offer: RTCSessionDescriptionInit = await this.peerConnections[agent.id].createOffer(offerOptions);
          // Establish the offer as the local peer's current description.
          await this.peerConnections[agent.id].setLocalDescription(offer);

          this.superviseMap[agent.id] = { loading: true }
          const res: any = await this.sendMessageWithAck({ type: 'offer', data: offer, agentId: agent.id, agencyId: agent.agencyId })
          if (agent.id) {
            if (res.success) {
              this.superviseMap[agent.id] = { inCall: true }
            } else {
              delete this.superviseMap[agent.id]
              this.toastService.error(res.message)
            }
          }
        } catch (err: any) {
          this.handleGetUserMediaError({ agentId: agent.id, agencyId: agent.agencyId }, err);
        }
      }
    }
  }

  async barge(agent: Agent, forceDisable = false) {
    if (agent.id) {
      if (!this.sendMyAudio[agent.id] && !forceDisable) {
        console.log('barge enable audio')
        await this.supervise(agent, true)
      }

      try {
        const enabled = !this.bargeEnabled[agent.id]?.enabled && !forceDisable
        this.bargeEnabled[agent.id] = { loading: true }

        const res: any = await this.sendMessageWithAck({ type: 'barge', data: { enabled }, agentId: agent.id, agencyId: agent.agencyId })
        console.log('barge res', { res, enabled })
        if (agent.id) {
          if (res.success) {
            this.bargeEnabled[agent.id] = { enabled: enabled }
          } else {
            this.bargeEnabled[agent.id] = { error: res.message }
            this.toastService.error(res.message)
          }
        }
      } catch (err: any) {
        console.error('barge error', err)
      }
    }
  }



  async hangUp(message: CallContainer) {
    if (message.agentId) {
      this.sendMessage({ ...message, type: 'hangup', data: '' });
      console.log('hangUp', message)
      this.closeCall(message.agentId);
    } else {
      console.error('no agentId')
    }
  }

  private addIncomingMessageHandler(): void {
    this.connect();

    // this.transactions$.subscribe();
    this.messages$.subscribe({
      next: (msg) => {
        // console.log('Received message: ' + msg.type);
        switch (msg.type) {
          case 'offer':
            this.handleOfferMessage(msg);
            break;
          case 'answer':
            this.handleAnswerMessage(msg, msg.data);
            break;
          case 'hangup':
            this.handleHangupMessage(msg);
            break;
          case 'barge':
            this.handleBargeMessage(msg);
            break;
          case 'ice-candidate':
            this.handleICECandidateMessage(msg, msg.data);
            break;
          default:
            console.log('unknown message of type ' + msg.type);
        }
      },
      error: (error) => console.log(error)
    });
  }

  /* ########################  MESSAGE HANDLER  ################################## */

  private async handleOfferMessage(message: Message) {
    const msg: RTCSessionDescriptionInit = message.data

    console.log('handle incoming offer', msg);
    if (!message.agentId) {
      throw new Error('no agentId')
    }

    // console.error('CURRENT USER', this.authService.userData)
    // if (this.authService.userData?.currentCall?.status === DialerAgentStatus.OFFLINE) {
    //   this.sendMessage({ type: 'hangup', data: '', agentId: message.agentId, agencyId: message.agencyId });
    //   return
    // }


    if (!this.peerConnections[message.agentId]) {
      this.createPeerConnection(message);
    }

    if (!this.peerConnections[message.agentId]) {
      console.error('no peerConnection')
      throw new Error()
    }

    await this.peerConnections[message.agentId].setRemoteDescription(new RTCSessionDescription(msg))

    if (!this.localStream) {
      await this.initializeLocalStream()
    }
    if (!this.localAudioActive) {
      this.startLocalStream();
    }

    if (this.telnyxService.currentCall?.remoteStream && !this.remoteSource) {
      this.initializeRemoteStream();
    }

    // add media stream to local video
    //this.localAudio.nativeElement.srcObject = this.localStream;

    // add media tracks to remote connection
    // this.localStream.getTracks().forEach(
    //   track => this.peerConnections[message.agentId].addTrack(track, this.localStream)
    // );
    if (!this.mixedStream) {
      throw new Error('no mixedStream')
    }

    for (const track of this.mixedStream.getTracks()) {
      track.enabled = true;
      this.peerConnections[message.agentId]?.addTrack(track, this.mixedStream)
    }

    const answer = await this.peerConnections[message.agentId]?.createAnswer();

    await this.peerConnections[message.agentId]?.setLocalDescription(answer);

    // Send local SDP to remote party
    this.sendMessage({ type: 'answer', data: this.peerConnections[message.agentId]?.localDescription, agentId: message.agentId, agencyId: message.agencyId });
  }

  private handleAnswerMessage(message: Message, msg: RTCSessionDescriptionInit): void {
    console.log('handle incoming answer');
    if (!message.agentId) {
      throw new Error('no agentId')
    }
    if (!this.peerConnections[message.agentId]) {
      console.log('call not active any more')
      this.closeCall(message.agentId)
      return
    }
    this.peerConnections[message.agentId].setRemoteDescription(msg);
  }

  private handleHangupMessage(msg: Message): void {
    console.log('handleHangupMessage', msg);
    this.closeCall(msg.agentId);
  }

  private async handleBargeMessage(msg: Message): Promise<void> {
    const enabled = msg?.data?.enabled
    console.log('bargeMessage', { msg, enabled });
    if (msg.agentId && this.telnyxService.currentCall) {
      // const audio = this.audioElement[msg.agentId]

      const sender = this.telnyxService.currentCall?.peer?.instance?.getSenders()
        .find(({ track }: RTCRtpSender) => track?.kind === 'audio');
      if (sender) {
        console.log('REPLACE TRACK SENDER FOUND', {
          enabled
        })

        if (enabled) {
          const newStream = this.remoteMediaStreams[msg.agentId].clone()

          this.bargeEnabledStream[msg.agentId] = newStream
        } else if (this.bargeEnabledStream[msg.agentId]) {
          // this.bargeEnabledStream[msg.agentId]?.getTracks()?.forEach(track => {
          //   console.error('stop track', track)
          //   // track.stop()
          // })
        }

        console.log('bargeEnabledStream', { bargeEnabledStream: this.bargeEnabledStream })

        // const userAudioStream = await this.telnyxService.getUserAudio()
        if (!this.localStream) {
          console.error('no localStream')
          throw new Error('no localStream')
        }

        const userAudioStream = this.localStream
        const streams = [userAudioStream]

        if (!enabled) {
          delete this.bargeEnabledStream[msg.agentId]
        }
        for (const key in this.bargeEnabledStream) {
          streams.push(this.bargeEnabledStream[key])
        }

        console.log('streams', streams)

        const tracks = this.telnyxService.createMixedAudioStream(streams).getAudioTracks()

        const firstTrack = tracks?.[0]

        if (firstTrack) {
          try {
            sender.replaceTrack(firstTrack);
          } catch (e) {
            console.error('Error replacing track', e)
            captureException(e)
          }
        }
      }
    }
  }

  private handleICECandidateMessage(message: Message, msg: RTCIceCandidate): void {
    const candidate = new RTCIceCandidate(msg);
    if (!message.agentId) {
      console.error('no peerConnection')
      throw new Error('no agentId')
    }
    if (!this.peerConnections[message.agentId]) {
      console.error('call already closed')
      this.closeCall(message.agentId)
      return
    }
    this.peerConnections[message.agentId].addIceCandidate(candidate).catch((e: Error) => {
      console.error('got Error: ' + e.name);
      console.log(e);
    })
  }

  public startLocalStream(): void {
    console.log('starting local stream');
    console.log('localAudio', this.localAudio)

    if (!this.mixedStream) {
      throw new Error('no mixedStream')
    }
    this.mixedStream.getTracks().forEach(track => {
      console.log('enable track', track)
      track.enabled = true;
    });
    if (!this.localAudio) {
      console.error('no localAudio')
      throw new Error()
    }
    // this.localAudio.nativeElement.srcObject = this.mixedStream;

    this.localAudioActive = true;
  }

  public removeLocalAudio(): void {
    console.log('removeLocalAudio');


    this.localSource?.disconnect()
    delete this.localSource

    this.mixedStreamDestination?.disconnect()
    delete this.mixedStreamDestination

    this.audioContext?.close()
    delete this.audioContext
    delete this.localStream


  }

  private createPeerConnection(message: CallContainer): void {
    console.log('creating PeerConnection...');
    if (!message.agentId) {
      throw new Error('no agentId')
    }
    this.peerConnections[message.agentId] = new RTCPeerConnection({
      iceServers: [
        {
          urls: environment.supervise.stunServer,
        },
        {
          urls: environment.supervise.turnServer,
          username: environment.supervise.turnUsername,
          credential: environment.supervise.turnCredential,
        }
      ],
    });

    this.peerConnections[message.agentId].onicecandidate = (ev) => this.handleICECandidateEvent(message, ev);
    this.peerConnections[message.agentId].oniceconnectionstatechange = (ev) => this.handleICEConnectionStateChangeEvent(message, ev);
    this.peerConnections[message.agentId].onsignalingstatechange = (ev) => this.handleSignalingStateChangeEvent(message, ev);
    this.peerConnections[message.agentId].ontrack = (ev) => this.handleTrackEvent(message, ev);
  }

  private closeCall(agentId?: string): void {
    console.log('Closing call');

    if (agentId) {
      delete this.superviseMap[agentId]

      if (this.localTracks[agentId]) {
        this.localTracks[agentId].stop()
        delete this.localTracks[agentId]
      }
    }

    if (agentId) {
      if (this.peerConnections[agentId]) {
        console.log('--> Closing the peer connection');

        this.peerConnections[agentId].ontrack = null;
        this.peerConnections[agentId].onicecandidate = null;
        this.peerConnections[agentId].oniceconnectionstatechange = null;
        this.peerConnections[agentId].onsignalingstatechange = null;

        // Stop all transceivers on the connection
        this.peerConnections[agentId].getTransceivers().forEach(transceiver => {
          transceiver.stop();
        });

        // Close the peer connection
        this.peerConnections[agentId].close();
        delete this.peerConnections[agentId]

        this.localAudioActive = false;

        // remove audio element from div with id superviseAudioContainer and delete it from audioElementMap
        if (this.bargeEnabledStream[agentId]) {
          this.bargeEnabledStream[agentId]?.getTracks().forEach(track => track.stop());
          this.barge({ id: agentId }, true)
        }

        if (this.audioElement[agentId]) {
          this.audioElement[agentId].remove();
          delete this.audioElement[agentId];

          this.remoteMediaStreams[agentId].getTracks().forEach(track => track.stop());
          delete this.remoteMediaStreams[agentId];
        }

        if (Object.keys(this.peerConnections).length === 0) {
          this.removeLocalAudio();
        }

        this.remoteSource?.disconnect()
        delete this.remoteSource
      }
    } else {
      for (const key in this.peerConnections) {
        this.closeCall(key)
      }
    }
  }

  /* ########################  ERROR HANDLER  ################################## */
  private handleGetUserMediaError(message: CallContainer, e: Error): void {
    console.log('handleGetUserMediaError', e)
    switch (e.name) {
      case 'NotFoundError':
        alert('Unable to open your call because no camera and/or microphone were found.');
        break;
      case 'SecurityError':
      case 'PermissionDeniedError':
        // Do nothing; this is the same as the user canceling the call.
        break;
      default:
        console.log('handleGetUserMediaError', e);
        alert('Error opening your camera and/or microphone: ' + e.message);
        break;
    }

    this.closeCall(message.agentId);
  }


  /* ########################  EVENT HANDLER  ################################## */
  private handleICECandidateEvent = (message: CallContainer, event: RTCPeerConnectionIceEvent) => {
    console.log('handleICECandidateEvent', event);
    if (event.candidate) {
      this.sendMessage({
        type: 'ice-candidate',
        data: event.candidate,
        agentId: message.agentId,
        agencyId: message.agencyId,
      });
    }
  }

  private handleICEConnectionStateChangeEvent = (message: CallContainer, event: Event) => {
    console.log('handleICEConnectionStateChangeEvent', event);
    if (!message.agentId) {
      throw new Error('no agentId')
    }
    if (!this.peerConnections[message.agentId]) {
      throw new Error()
    }
    switch (this.peerConnections[message.agentId].iceConnectionState) {
      case 'closed':
      case 'failed':
      case 'disconnected':
        console.log('close call on ICEConnectionStateChangeEvent', this.peerConnections[message.agentId])
        this.closeCall(message.agentId);
        break;
    }
  }

  private handleSignalingStateChangeEvent = (message: CallContainer, event: Event) => {
    console.log('handleSignalingStateChangeEvent', event);
    if (!message.agentId) {
      throw new Error('no agentId')
    }
    if (!this.peerConnections[message.agentId]) {
      throw new Error()
    }
    switch (this.peerConnections[message.agentId].signalingState) {
      case 'closed':
        console.log('close call on SignalingStateChangeEvent')
        this.closeCall(message.agentId);
        break;
    }
  }

  private handleTrackEvent = (message: CallContainer, event: RTCTrackEvent) => {
    console.log('handleTrackEvent', event);

    // add audio element to div with id superviseAudioContainer and save it in superviseMap
    const audio = document.createElement('audio');
    audio.srcObject = event.streams[0];
    audio.autoplay = true;
    audio.controls = false;
    audio.muted = false

    if (this.audioService.audioSettings?.deviceId && audio) {
      audio.setSinkId(this.audioService.audioSettings.deviceId as string)
    }

    if (!message.agentId) {
      throw new Error('no agentId')
    }

    this.remoteMediaStreams[message.agentId] = event.streams[0];
    this.audioElement[message.agentId] = audio;

    const audioContainer = document.getElementById('superviseAudioContainer');
    if (audioContainer) {
      audioContainer?.appendChild(audio);
    } else {
      console.error('no audio container')
    }
  }







  ////_______

  async connect() {

    console.log('socket connect')
    await this.getNewWebSocket();

    if (this.socket) {
      console.log('start receiving')
      this.socket.on('message', msg => {
        console.warn('received message', msg)
        if (msg instanceof Blob) {
          // Handle Blob data
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const text = reader.result as string;
              const data = JSON.parse(text);
              console.log('Received message of type: ' + data.type);
              this.messagesSubject.next(data);
            } catch (error) {
              console.error('Failed to parse Blob to JSON', error);
            }
          };
          reader.readAsText(msg);
        } else {
          console.log('Received message of type: ' + msg.type);
          this.messagesSubject.next(msg);
        }
      });
    } else {
      console.error('no socket while connect')
    }
  }

  sendMessage(msg: Message): void {
    console.log('sending message ' + msg.type, msg);
    // if (this.socket$) {
    //   this.socket$.next(msg);
    // }

    if (this.socket) {
      this.socket.send('message', JSON.stringify(msg))
    } else {
      console.error('no socket while send message')
    }
  }

  sendMessageWithAck = (msg: Message) => new Promise(resolve => {
    console.log('sending message with ack ' + msg.type, msg);
    // if (this.socket$) {
    //   this.socket$.next(msg);
    // }

    if (this.socket) {
      this.socket.send('message', JSON.stringify(msg), (ack: any) => resolve(ack))
    } else {
      console.error('no socket while messageWithAck')
    }
  })

  private getNewWebSocket = (): Promise<void> => new Promise(resolve => {
    this.ngZone.runOutsideAngular(() => {
      if (!this.socket) {
        this.authService.getIdToken().then((token) => {
          const agencyId = this.authService.userData?.userType === UserType.AGENT ? this.authService.userData?.currentAgency?.id : null
          console.log('SOCKET CONST TOKEN', agencyId);
          this.socket = io(environment.supervise.server, {
            query: {
              token: token,
              userId: this.authService.user?.uid
              // agencyId
            },
            reconnection: false,
          });

          this.socket.on('connect', () => {
            console.log('connected');

            this._wasPongReconnect = false
            resolve()
          });

          this.socket.on("pong", () => {
            console.log("ping check pong received");
            clearTimeout(this._pingTimeout);
          });

          this.socket.on('connect_error', (err) => {
            console.log('connect_error', err);

            if (this._reconnectTimeout) {
              clearTimeout(this._reconnectTimeout);
            }
            if (!this._wasPongReconnect) {
              this._reconnectTimeout = setTimeout(() => {
                console.log('reconnect after connect_error')
                this.socketReconnect()
              }, 5000);
            }
          });

          this.socket.on('disconnect', () => {
            console.log('disconnect');

            if (this._reconnectTimeout) {
              clearTimeout(this._reconnectTimeout);
            }
            if (!this._wasPongReconnect) {
              this._reconnectTimeout = setTimeout(() => {
                console.log('reconnect after disconnect')
                this.socketReconnect()
              }, 5000);
            }
          });
        });
      }
    })
  })

  private async socketReconnect() {
    console.log('socket reconnect')

    this.connect()

    if (this.socket) {
      clearTimeout(this._reconnectTimeout)
      this.socket.disconnect()
      const token = await this.authService.getIdToken();
      const agencyId = this.authService.userData?.userType === UserType.AGENT ? this.authService.userData?.currentAgency?.id : null

      this.socket.io.opts.query = { token, agencyId };
      try {
        this.socket.open();
      } catch (err) {
        console.log('connect_error on reconnect', err);
      }
    } else {
      console.error('no socket while reconnect')
    }
  }

  // forceDisconnect() {
  //   if (this.socket) {
  //     this._wasPongReconnect = true
  //     this.socket.disconnect()
  //     setTimeout(() => this._wasPongReconnect = false, 100)
  //   }
  // }
}
